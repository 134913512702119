<template>
<div>
  <!--TITULOS  -->
  <div class="air__utils__heading"> <h5> Programación Orden de Producción </h5> </div>
  <my-breadcrumbs :routes="routes"/>
  <!--FILTROS  -->
  <a-row class="mb-4 mt-4 bg-white" align="middle" :gutter="[8,24]">
    <a-col :sm="24" :lg="12">
      <antGlobalPlantFilter v-model="filterPlant"/>
    </a-col>
  </a-row>
  <!--FILTROS  -->
  <!--FORMULARIO-->
  <a-row class="mb-4 mt-4 bg-white p-4" align="middle" :gutter="[8,24]">
    <a-form-model :model="resourceObj.form"
                  v-show="!spinnerLoad"
                  :rules="formRules"
                  ref="productionForm" layout="vertical">
      <h5>Datos generales de planeación de producción</h5>
      <hr class="bg-primary">
      <!--TEXT - No.Orden/Lote-->
      <a-row align="middle" :gutter="[8,24]">
        <a-col :sm="24" :md="8">
          <a-form-model-item label="No.Orden/Lote" ref="code"  prop="code" >
            <a-input v-model="resourceObj.form.code" disabled
                     placeholder="No.Orden/Lote" size="large"/>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="16">
          <a-form-model-item label="Turno" ref="operationShifts" prop="operationShifts" >
            <ResourceSelect v-model="resourceObj.form.operationShifts"
                            urlResource="/operationShifts"
                            :filters="shiftParams"
                            placeholder="Seleccionar Turno">
              <span slot-scope="{option}">
                {{ option.name }}
                <small>({{ moment(option.initial_time).format('HH:mm') }} -
                {{ moment(option.final_time).format('HH:mm') }} )</small>
              </span>
            </ResourceSelect>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!--SELECT -Turno  -->
      <!--TEXT - Técnico de Operación-->
      <a-row align="middle" :gutter="[8,24]">
        <a-col :sm="24" :md="12">
          <a-form-model-item label="Técnico de Operación" ref="technicalPrimes"  prop="technicalPrimes" >
            <ResourceSelect v-model="resourceObj.form.technicalPrimes"
                            urlResource="/users"
                            placeholder="Seleccionar Técnico de Operación">
              <span slot-scope="{option}">{{ option.name }}</span>
            </ResourceSelect>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-form-model-item label="Técnico 2 de Operación" ref="technicalSecondaries"  prop="technicalSecondaries" >
            <ResourceSelect v-model="resourceObj.form.technicalSecondaries"
                            urlResource="/users"
                            placeholder="Seleccionar Técnico 2 de Operación">
              <span slot-scope="{option}">{{ option.name }}</span>
            </ResourceSelect>
          </a-form-model-item>
        </a-col>
      </a-row>
      <h5>Información de la planeación de la orden de producción</h5>
      <hr class="bg-primary">
      <!--SELECT -Tipo de Proceso & Formulacion -->
      <a-row align="middle" :gutter="[8,24]">
        <!--SELECT -Tipo de Proceso  & Formulacion -->
        <a-col :sm="24" :md="12">
          <a-form-model-item label="Tipo de Proceso" ref="processTypes" prop="processTypes" >
            <ResourceSelect v-model="resourceObj.form.processTypes"
                            urlResource="/processTypes"
                            placeholder="Seleccionar Proceso">
              <span slot-scope="{option}">{{ option.name }}</span>
            </ResourceSelect>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-form-model-item label="Tipo de Formulación" ref="formulationTypes" prop="formulationTypes" >
            <ResourceSelect v-model="resourceObj.form.formulationTypes"
                            urlResource="/formulationTypes"
                            :filters="formulationTypeParams"
                            placeholder="Seleccionar Formulación">
              <span slot-scope="{option}">{{ option.name }}</span>
            </ResourceSelect>
          </a-form-model-item>
        </a-col>
      </a-row>
      <h5>Planeación de producción</h5>
      <hr class="bg-primary">
      <!--DATEPICKER - Fecha y hora de inicio de plan-->
      <a-row align="middle" :gutter="[8,24]">
        <a-col :sm="24" :md="12">
          <a-form-model-item  label="Fecha y hora de inicio de plan" ref="started_at" prop="started_at" >
            <a-date-picker  v-model="resourceObj.form.started_at" style="width: 100%"
                            placeholder="Fecha y hora de inicio de plan"
                            format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY/MM/DD HH:mm"/>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-form-model-item  label="Fecha y hora de final de plan" ref="finished_at" prop="finished_at" >
            <a-date-picker  v-model="resourceObj.form.finished_at" style="width: 100%"
                            placeholder="Fecha y hora de final de plan"
                            format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY/MM/DD HH:mm"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!--DATEPICKER - Fecha y hora de final de plan-->
      <hr class="bg-primary">
      <!--Numbers - Cantidades-->
      <a-row align="middle" :gutter="[8,24]">
        <!--NUMBER INPUT - Horas Trabajadas-->
        <a-col :sm="24" :md="8">
          <a-form-model-item label="Horas Trabajadas" ref="worked_hours"  prop="worked_hours" >
            <a-input-number v-model.number="resourceObj.form.worked_hours" disabled
                            style="width: 100%" :min="0" :step="1" size="large"/>
          </a-form-model-item>
        </a-col>
        <!--NUMBER INPUT - Cantidad Total de Lote Terminado-->
        <a-col :sm="12" :md="8">
          <a-form-model-item label="Cantidad Total de Lote Terminado" ref="total_quantity"  prop="total_quantity" >
            <a-input-number v-model.number="resourceObj.form.total_quantity" disabled
                            style="width: 80%" :min="0" :step="1" size="large"/>
            <span class="ml-1">TON</span>
          </a-form-model-item>
        </a-col>
        <!--NUMBER INPUT - Contenedores Totales-->
        <a-col :sm="12" :md="8">
          <a-form-model-item label="Contenedores Totales" ref="total_containers"  prop="total_containers" >
            <a-input-number v-model.number="resourceObj.form.total_containers" disabled
                            style="width: 80%" :min="0" :step="1" size="large"/>
            <span class="ml-1">PZS</span>
          </a-form-model-item>
        </a-col>
        <!--NUMBER INPUT - Costo Total de insumos-->
        <a-col :sm="12" :md="8">
          <a-form-model-item label="Costo Total de insumos" ref="supplies_cost"  prop="supplies_cost" >
            <span class="mr-1">$</span>
            <a-input-number v-model.number="resourceObj.form.supplies_cost" disabled
                            style="width: 80%" :min="0" :step="1" size="large"/>
          </a-form-model-item>
        </a-col>
        <!--NUMBER INPUT - Costo T/Agua-->
        <a-col :sm="12" :md="8">
          <a-form-model-item label="Costo T/Agua" ref="water_cost"  prop="water_cost" >
            <span class="mr-1">$</span>
            <a-input-number v-model.number="resourceObj.form.water_cost" disabled
                            style="width: 80%" :min="0" :step="1" size="large"/>
          </a-form-model-item>
        </a-col>
        <!--NUMBER INPUT - Cantidad de Lodo Residual-->
        <a-col :sm="12" :md="8">
          <a-form-model-item label="Cantidad de Lodo Residual" ref="mud_quantity"  prop="mud_quantity" >
            <a-input-number v-model.number="resourceObj.form.mud_quantity" disabled
                            style="width: 80%" :min="0" :step="1" size="large"/>
            <span class="ml-1">TON</span>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!--Numbers - Cantidades-->
      <hr class="bg-primary">
      <!--RADIOS - ESTATUS ||| SWITCH - TIRES-->
      <a-row align="middle" :gutter="[8,24]" type="flex" justify="center">
        <a-col :sm="{span:12, offset:2}">
          <a-form-model-item  label="Estatus" ref="status" prop="status" >
            <a-radio-group v-model="resourceObj.form.status" disabled default-value="Abierto" button-style="solid">
              <a-radio-button v-for="status in formulationStatusesCombo" :key="status" :value="status">{{status}}</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :sm="{span:6, offset:4}">
          <!--SWITCH - TIRES-->
          <a-form-model-item  label="Llantas" ref="tires" prop="tires" >
            <a-switch v-model="resourceObj.form.tires">
              <a-icon slot="checkedChildren" type="check" />
              <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
          </a-form-model-item>
          <!--SWITCH - TIRES-->
        </a-col>
      </a-row>
      <!--RADIOS - ESTATUS ||| SWITCH - TIRES-->
      <hr class="bg-primary">
      <a-row class="mt-3" align="middle" type="flex" justify="end">
        <a-col class="mr-4">
          <b-button v-if="!lockedByStatus" @click="Submit"
                    variant="primary" pill block>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
              </div>
          </b-button>
        </a-col>
        <a-col class="mr-4">
          <AddQuantityDetailAndCloseBatchable v-if="urlId"
                                              :batchable_id="urlId"
                                              batchable_type="formulations"
                                              :status="resourceObj.form.status"
                                              @Updated="SetForm"/>
        </a-col>
      </a-row>
    </a-form-model>
    <my-spinner :load="spinnerLoad" />
  </a-row>
  <!--FORMULARIO-->

  <a-row v-if="urlId" class="mt-2 py-4 px-2 bg-white">
    <div class="col-12">
      <a-tabs type="card" v-model="activeTabKey">
        <a-tab-pane key="1">
          <span slot="tab"><a-icon type="form"/> Residuos</span>
          <div class="card">
            <div class="card-body text-center">
              <materialTab :work-center="filterPlant"
                           :formulation-id="urlId"
                           :locked="lockedByStatus"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="usergroup-add" />
            Personal
          </span>
          <div class="card">
            <div class="card-body text-center">
              <personalTab :work-center="filterPlant"
                           :staffable-id="urlId"
                           :locked="lockedByStatus"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">
            <a-icon type="check-square" />
            Pruebas de Compatibilidad
          </span>
          <div class="card">
            <div class="card-body text-center">
              <create-new-test test-type="compatibilityAnalysis"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">
            <a-icon type="experiment" />
            Análisis Terminado
          </span>
          <div class="card">
            <div class="card-body text-center">
              <create-new-test test-type="batchAnalysis"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="5">
          <span slot="tab">
            <a-icon type="rest" />
            Registro de Tambos Vacíos
          </span>
          <div class="card">
            <div class="card-body text-center">
              Registro de Tambos Vacíos
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="6">
          <span slot="tab">
            <a-icon type="rest" />
            Lote terminado
          </span>
          <div class="card">
            <div class="card-body text-center">
              <batchFinishedTab :batchable_id="urlId" :locked="!lockedByStatus"/>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-row>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'
import { formulationStatusesCombo, formulationStatuses } from '@/constant/formulations'
import CreateNewTest from '@/views/geocycle/laboratories/laboratory_tests/createNewTest'
import batchFinishedTab from './components/production_order_scheduling_tabs/batch-finished-tab'
import materialTab from './components/production_order_scheduling_tabs/material-tab'
import personalTab from './components/production_order_scheduling_tabs/personal-tab'
import AddQuantityDetailAndCloseBatchable from './components/AddQuantityDetailAndCloseBatchable'
// temporal data
export default {
  name: 'productionOrderScheduling',
  mixins: [fractalMixin],
  components: {
    materialTab,
    personalTab,
    CreateNewTest,
    batchFinishedTab,
    AddQuantityDetailAndCloseBatchable,
  },
  data() {
    return {
      // constantes
      formulationStatusesCombo: formulationStatusesCombo,
      formulationStatuses: formulationStatuses,
      // url
      urlId: this.$route.params.id,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Proceso Operativo',
          name: 'operation_process',
        },
        {
          name: 'formulation',
          breadcrumbName: 'Formulación',
        },
        {
          breadcrumbName: 'Programación Orden de Producción',
        },
      ],
      activeTabKey: '1',
      resourceObj: {
        resourceType: '',
        form: {
          id: '',
          code: '',
          operationShifts: undefined,
          formulationTypes: undefined,
          processTypes: undefined,
          technicalSecondaries: undefined,
          technicalPrimes: undefined,
          started_at: '',
          finished_at: '',
          worked_hours: 0,
          status: formulationStatuses.abierto,
          tires: false,
          // Cantidades para el cierre
          total_quantity: 0,
          total_containers: 0,
          supplies_cost: 0,
          water_cost: 0,
          mud_quantity: 0,
        },
        relationships: ['operationShifts', 'formulationTypes', 'processTypes'],
      },
      formRules: {},
      spinnerLoad: false,
      // filtros
      formulationTypeParams: {},
      processTypeParams: {},
    }
  },
  computed: {
    ...mapState('productionOrders', ['selectedWC']),
    shiftParams() {
      return {
        sort: 'name',
        // 'filter[workCenters]': '',
      }
    },
    filterPlant: {
      get() {
        return this.selectedWC
      },
      set(value) {
        this.SAVE_WORK_CENTER(value)
      },
    },
    // variable for lock the form and the tabs
    lockedByStatus() {
      return this.resourceObj.form.status !== this.formulationStatuses.abierto
    },
  },
  methods: {
    ...mapActions('productionOrders', ['SAVE_WORK_CENTER', 'RESET_WORK_CENTER']),
    async SetForm() {
      // Consulta de formulacion via api
      const formulation = await this.GetResource(`/formulations/${this.urlId}`, {
        include: 'operationShifts,formulationTypes,processTypes,technicalPrimes,workCenters,technicalSecondaries',
      })
      // Asignación de información
      const { operationShifts, formulationTypes, processTypes, technicalPrimes, technicalSecondaries, titems, ...rest } = formulation
      Object.assign(rest, {
        operationShifts: operationShifts.id,
        formulationTypes: formulationTypes.id,
        processTypes: processTypes.id,
        technicalPrimes: technicalPrimes.id,
        technicalSecondaries: technicalSecondaries?.id || undefined,
      })
      Object.assign(this.resourceObj.form, rest)

      // Set de workcenter en estado segun formulación
      this.SAVE_WORK_CENTER(formulation.workCenters.id)
    },
    async Submit() {
      this.$refs.productionForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            await this.AxiosPost('/save-formulation', {
              ...this.resourceObj.form,
              work_center_code: this.selectedWC,
            }, (resp) => {
              this.$router.push({ name: 'production_order_scheduling', params: { id: resp.data.formulation.id } })
              location.reload()
            }, () => {
              this.spinnerLoad = false
            })
            // Post
          } else {
            // Put
            await this.AxiosPut(`/update-formulation/${this.urlId}`, {
              ...this.resourceObj.form,
              work_center_code: this.selectedWC,
            }, (resp) => {
              this.spinnerLoad = false
              console.log('updated', resp.data)
            }, () => { this.spinnerLoad = false })
          }
        } else return false
      })
    },
    // Residuos
  },
  mounted() {
    if (this.urlId) {
      this.SetForm()
    }
  },
}
</script>

<style scoped>

</style>
