<template>
  <div>
    <span class="font-weight-bold">
      {{ testType }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'createNewTest',
  props: {
    testType: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
